<template>
  <div id="app">
    <!-- <Mapview>      </Mapview> -->
    <div id="allmap" style="overflow:hidden;zoom:1;position:relative;">
        <!-- </Mapview> -->
    <!-- <el-upload
      class="upload-demo"
      :action="uploadUrl"
      :on-change="handleFileChange"
      :before-upload="beforeUpload"
      multiple
      :file-list="fileList"
      accept=".geojson"
      style="position: absolute; bottom: 20px; right: 20px; z-index: 1000;">
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">只能上传 .geojson 文件</div>
    </el-upload> -->
    </div>
      <div class="center-panel">物理环境数字化重建工具</div>
      <!-- <div class="control-panel" v-if="showCascader">
        <el-cascader
          v-model="selectedValues"
          :options="options"
          @change="handleCascaderChange"
          placeholder="请选择"
          style="width: 300px; margin-top: 10px;"
        ></el-cascader>

        <el-button  type="primary" @click="confirmSelection" style="margin-top: 10px;">确认选择</el-button>
      </div> -->
      <!-- <el-button class="finishChild" v-if="showCascaderButton" @click="childset">子区圈选完成</el-button> -->
      <el-dialog
        title="出入口设置"
        :visible.sync="inandout"
        @close="resetSelectList"
      >
      <el-button type="primary" @click="addSelect" >添加选择器</el-button>
        <div v-for="(item, index) in selectList" :key="index" class="select-item">
          <span class="label">入口{{ index + 1 +" "}}</span>
          <!-- 第一个选择框 -->
          <el-select v-model="item.selected" placeholder="请选择" @change="updateSelections">
            <el-option
              v-for="option in filteredPointsReback(index, false)"
              :key="option.coord.toString()"
              :label="option.id"
              :value="option.coord"
            ></el-option>
          </el-select>

          <!-- 第二个选择框 -->
          <el-select v-model="item.selected2" placeholder="请选择" @change="updateSelections">
            <el-option
              v-for="option in filteredPointsReback(index, true)"
              :key="option.coord.toString()"
              :label="option.id"
              :value="option.coord"
            ></el-option>
          </el-select>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitSelections">确 定</el-button>
        </span>
      </el-dialog>
        <div id="map" style="height:100%;-webkit-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;">
        </div>
          <div v-if="subdivideRegion" class="select-box">
            <el-select v-model="selectedLocation" placeholder="选择子区域" @change="handleSelectionChange" >
              <el-option
                v-for="(rectangle, index) in rectangles"
                :key="index"
                :label="rectangle.name"
                :value="rectangle.name"
              ></el-option>
            </el-select>
          </div>
          <div v-if="subdivideRegion" class="input-boxx">
            <el-input
              class="input-row-column"
              placeholder="切分行数"
              v-model="row"
              clearable>
            </el-input>
            <el-input
              class="input-row-column"
              placeholder="切分列数"
              v-model="column"
              clearable>
            </el-input>
            <el-button @click="handleCancel">取消</el-button>
            <el-button @click="handleBlur">确认</el-button>
          </div>
          <div style="height: 300px;" class="steps">
            <el-steps direction="vertical" :active=active finish-status="success">
              <el-step title="开始构建"></el-step>
              <el-step title="区域名称"></el-step>
              <el-step title="边界"></el-step>
              <el-step title="子区域"></el-step>
              <el-step title="出入口"></el-step>
              <el-step title="子区细分"></el-step>
              <el-step title="导出"></el-step>
            </el-steps>
          </div>
          <div id="next-step">
            <!-- <el-button @click="previousStep">上一步</el-button> -->
            <el-button @click="nextStep">{{ buttonText }}</el-button>
          </div>
          <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-change="handleFileChange"
            :before-upload="beforeUpload"
            multiple
            :file-list="fileList"
            accept=".geojson"
            style="position: absolute; bottom: 20px; right: 20px; z-index: 1000;">
            <el-button  class="custom-button" @click="importData">导入</el-button>
            <!-- <div slot="tip" class="el-upload__tip">上传 .geojson 文件</div> -->
          </el-upload>
          <div class="button-container">
            <!-- <el-button class="custom-button">导入</el-button> -->
            <el-button class="custom-button" @click="resetData">重置</el-button>
            <el-button class="custom-button" @click="hide">{{ labelVisible ? '隐藏标签' : '显示标签' }}</el-button>
            <el-button class="custom-button" @click="rollback">回退</el-button>
          </div>
          <!-- <el-tabs  :tab-position="'right'" v-model="activeName" @tab-click="handleClick" class="custom-tabs">
            <el-tab-pane @click="handlechoose('first')" label="区域名称" name="first" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('second')" label="边界" name="second" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('third')" label="子区域" name="third" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('fourth')" label="出入口" name="fourth" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('fifth')" label="子区细分" name="fifth" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('sixth')" label="导入" name="sixth" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('seventh')" label="导出" name="seventh" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('eighth')" label="重置" name="eighth" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('ninth')" :label="labelVisible ? '隐藏标签' : '显示标签' " name="ninth" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('tenth')" label="回退" name="tenth" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('tenth')" label="编辑" name="eleventh" class="large-font"></el-tab-pane>

          </el-tabs> -->
  </div>
</template>
<script src="https://webapi.amap.com/maps?v=2.0&key=您申请的key值&plugin=AMap.MouseTool"></script>

<script>
import Mapview from "./components/MapContainer.vue"
import axios from 'axios';
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  name: 'App',
  components: {
    Mapview
  },
  data() {
    return {
      showCascader: false,
      showCascaderButton:false,
      inandout: false,
      subdivideRegion:false,
      choicevalue:"",//0,1,2
      areaName:"",
      row:"",
      column:"",
      base:0,//记录点的基础的
      labeledPoints: new Set(), // 用于存储已添加标签的点
      areaNamBoundary:"",
      areaNameAisle:"",
      areaNameEntranceExit:"",
      areaNameSubarea:"",
      cascaderValue:"",
      areaCenterPoints:[],
      polygonPoints : [],
      rectangles:[],//用于存储命名后的area的矩形数组，子区细分部分
      polygonData:[],
      map: null,
      overlays: [],
      drawingManager: null,
      styleOptions: {
        strokeColor: "red",
        fillColor: "red",
        strokeWeight: 3,
        strokeOpacity: 0.8,
        fillOpacity: 0.6,
        strokeStyle: 'solid'
      },
      ret:{},
      selectedLocation: [],
      locations: [
        { value: 'location1', label: '位置 1' },
        { value: 'location2', label: '位置 2' },
        { value: 'location3', label: '位置 3' },
      ],
      // rectangles:[],//矩形子模块命名加点位
      points: {
        pointA: { lng: null, lat: null },
        pointB: { lng: null, lat: null },
        pointC: { lng: null, lat: null },
        pointD: { lng: null,  lat: null }
      },
      temporary:[],
      allPoints:[], //data 中定义 polygons 数组
      pointsReback:[],//
      selectList: [{ selected: null }], // 初始选择器
      childrenoptions: [ // 可选项
        { value: 'option1', label: '1' },
        { value: 'option2', label: '2' },
        { value: 'option3', label: '3' },
      ],
      options: [
        {
          value: 'A',
          label: '手动圈选',
          children: [
            { value: 'A1', label: '吸附边缘' },
            { value: 'A2', label: '不吸附' }
          ]
        },
        {
          value: 'B',
          label: '自动划分',

        }
      ],
      textLabels:[],
      activeName: '', // 默认激活的标签
      labelVisible:true,//用于地图标签初始化 显示
      lastTextNumber:0,//在回撤时，需要用来确定需要回撤标签的数量
      intersectingLine:[],
      active:0,
      boundary:[],//单独用来存储导出的数据。边界
      aisle:[],//过道
      exit:[],//出入口,这个好弄selectList
      subarea:[],//细分区域.
      numstep:0,//用来区分进栈的步骤
      uploadUrl: '', // 上传地址 (可以留空，因为我们不上传到服务器)
      fileList: [], // 存储选择的文件
      drawLine:[],
    };
  },
  mounted() {
      this.initMap();
  },
  computed:{
    buttonText() {
      if (this.active === 0) {
        return '开始构建'; // active 为 0 时显示 "开始"
      } else if (this.active === 7) {
        return '完成'; // active 为 8 时显示 "完成"
      } else {
        return '下一步'; // 其它情况显示 "下一步"
      }
    }
  },
  methods: {
    handleClick(){ 
      this.handleChoose(this.activeName);
    },
    handleChoose(num){
      console.log("num",num);
      
      switch(num){
        case 'first': this.setAreaName(); // 确保这是一个方法调用
          break;
        case 'second':this.setBoundary(); // 确保这是一个方法调用
          break;
        case 'third':this.showCascader = true ; // 确保这是一个方法调用
          break;
        case 'fourth':this.inandout = true;
                      this.showCascaderButton=false; // 确保这是一个方法调用
          break;
        case 'fifth':this.handleSubdivide(); // 确保这是一个方法调用
                      this.showCascaderButton=false;
          break;
        case 'sixth':this.importData(); // 确保这是一个方法调用
          break;
        case 'seventh':this.exportData(); // 确保这是一个方法调用
          break;
        case 'eighth':this.reset(); // 确保这是一个方法调用
                      this.showCascaderButton=false;
          break;
        case 'ninth':this.hide();
          break;
        case 'tenth':this.rollback();
          break;
        case 'eleventh':this.rollback();
          break;
      }

    },
    toggleCascader(){
      this.showCascader = true; // 设置 showCascader 的值
      console.log(this.showCascader); // 输出 showCascader 的值
    },
    initMap() {
      console.log("初始化成功")
      // AMapLoader.reset()
      window._AMapSecurityConfig = {
        securityJsCode: "a430f608bf068cee59ae33ac8268a329",
      };
      AMapLoader.load({
        key: "2781d6dd22a0848f2362c76d52c8f05f", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale","AMap.MouseTool"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("allmap", {
            // 设置地图容器id
            resizeEnable: true,
            expandZoomRange:true,
            zoom:18, //设置初始化级别
            zooms:[3,20], //设置缩放级别范围 3-20 级
            center: [116.281138,39.878109], // 初始化地图中心点位置
          });          
          this.map.setMapStyle("amap://styles/whitesmoke");
          this.drawingManager = new AMap.MouseTool(this.map); 
        //监听draw事件可获取画好的覆盖物
        this.overlays = [];
        this.drawingManager.on('draw',this.overlayComplete) 
        this.draw('polygon');
          })
          .catch((e) => {
            console.log(e);
          });
    },
    draw(){
          console.log("draw");     
          this.drawingManager.polygon({
              fillColor:'#00b0ff',
              strokeColor:'#80d8ff'
            });
    },
    //画图完成后，获取标记的矩形多边形。
    overlayComplete(e) {
      this.overlays.push(e.obj);
      console.log("覆盖物已完成:", e.obj);
      console.log(e.obj);
      const path = e.obj.getPath();
      console.log("path",path);
      console.log("多边形的点数量",path.length);
      // if(e.obj.getPath.length<4){
      //   //让多边形一下的鼠标事件不在地图上显示
      //   return
      // }
      let temporypoint=[];
      this.lastTextNumber=0;
      path.forEach((point, index) => {
        if (index < path.length) { // 根据多边形点数画
          this.points[`point${String.fromCharCode(65 + index)}`] = { // A, B, C, D
            lat: point.lat,
            lng: point.lng
          };
          temporypoint.push([point.lat,point.lng]);//通过插入坐标，在循环完成后 获取零时矩形；
          const label = new AMap.Text(`拐角 ${this.base+index + 1}`, { position: point }); // 标注内容为 "点 1", "点 2", 等等
          var text = new AMap.Text({
            text:`拐角 ${this.base+index + 1}`,
            anchor:'center', // 设置文本标记锚点
            draggable:true,
            cursor:'pointer',
            angle:0,
            style:{
              'padding': '.5rem 1rem', // 调整内边距
              'margin-bottom': '0.5rem', // 调整下边距
              'border-radius': '.25rem',
              'background-color': 'transparent',
              'width': '3.5rem',// 减小宽度
              'border-width': 0,
              'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
              'text-align': 'center',
              'font-size': '16px', // 减小字体大小
              'color': 'blue'
            },
            position: point
        });
        text.setMap(this.map);
        this.textLabels.push(text); // 存储标签
        this.lastTextNumber++;//在回撤时，需要用来确定需要回撤标签的数量
        // this.textLabels.push(text); // 存储标签
        }    
      });
      this.allPoints.push(temporypoint);
      console.log(this.allPoints);
      this.base+=path.length;
      console.log(this.base+"base");
    },
    handleRightClick(e) {
      if (confirm(`Coordinates: ${e.point.lng}, ${e.point.lat}`)) {
        const shapeDiv = this.$el.querySelector("#shape");
        shapeDiv.innerHTML += `<br/>(${e.point.lng}, ${e.point.lat})`;
      }
    },
    handleCascaderChange(value) {
      // 处理级联选择器的变化
      console.log("当前选择的值:", value);
      if(value=="A - A1"){//吸附
        this.choicevalue=0;
      }else if(value="A - A2"){//不吸
        this.choicevalue=1;
      }else if("B"){
        this.choicevalue=2;
      }
      this.cascaderValue=value;
      console.log(this.cascaderValue);
    },
    confirmSelection() {
      this.choicevalue=2;
      if(this.choicevalue===2){
        this.drawingManager.polyline({
          strokeColor: '#FF0000', // 线条颜色
          strokeWeight: 6, // 线条宽度
          strokeOpacity: 0.8, // 线条透明度
          lineJoin: 'round', // 线条连接点样式
          lineCap: 'round' // 线条端点样式
        })
      }else{
      //将多边形覆盖重置
      this.drawingManager.polygon({
              fillColor:'#00b0ff',
              strokeColor:'#80d8ff'
            });
          }
      // 输出选择的类型
      console.log("您选择的类型:", this.selectedValues.join(' - '));
        // 隐藏级联选择器
        this.showCascader = false;
        this.showCascaderButton=true;
        this.styleOptions.strokeColor = "blue"; // 修改线条颜色
        this.styleOptions.fillColor = "lightblue"; // 修改填充颜色
        this.styleOptions.strokeWeight = 5; // 修改线条宽度
        // this.drawingManager.setPolygonOptions(this.styleOptions);
        // 可以在这里添加其他逻辑，例如更新状态或发送数据
        this.drawingManager.on();
        //this.drawingManager.setDrawingMode(AMap_DRAWING_POLYGON);
        console.log(this.drawingManager);
        console.log("子区域",this.allPoints); 
        //this.circle();
        console.log("将矩形成功进行输入")
    },
    cleanData(data) {
        return data.map(item => {
            // 创建一个新的数组，去掉 __ob__ 属性
            return item.map(point => {
                return point; // 直接返回坐标数组
            });
        });
    },
    withoutTouch(){
            this.allPoints.forEach((polygon, polygonIndex) => {
            polygon.forEach((coord, index) => {
              var text = new AMap.Text({
                  text:`拐角 ${this.base+index + 1}`,
                  anchor:'center', // 设置文本标记锚点
                  draggable:true,
                  cursor:'pointer',
                  angle:0,
                  style:{
                    'padding': '.5rem 1rem', // 调整内边距
                    'margin-bottom': '0.5rem', // 调整下边距
                    'border-radius': '.25rem',
                    'background-color': 'transparent',
                   'width': '3.5rem',// 减小宽度
                    'border-width': 0,
                    'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
                    'text-align': 'center',
                    'font-size': '16px', // 减小字体大小
                    'color': 'blue'
                  },
                  position: new AMap.Point(coord[1], coord[0]) 
              });
              text.setMap(this.map);
              this.textLabels.push(text); // 存储标签
              this.labeledPoints.add(`${coord[1]},${coord[0]}`); // 记录该点已经添加标签
              this.pointsReback.push({id: index + 1, coord: [coord[1], coord[0]], polygonId: polygonIndex + 1});
            });
          });

          this.base = this.allPoints.reduce((total, polygon) => total + polygon.length, 0); 
    
    },
    async circle(){
        //await new Promise(resolve => setTimeout(resolve, 8000));//解决函数异步问题
        const params=this.allPoints;
        console.log("params childeran",params);
         //const response = await fetch('https://gdp-api.shunshun.guoch.xyz/api/action', {
          const response = await fetch('http://192.168.124.6:5050/api/action', {
          
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
          });
          console.log(JSON.stringify(params))
        //   this.aisle= params.map(polygon => {
        //     return polygon.map(point => {
        //         // 交换经度 (longitude) 和纬度 (latitude)
        //         return [point[1], point[0]];
        //     });
        // });;
          const data = await response.json();
          this.aisle=data;
          this.showPolygon(data);
          console.log(data);
          console.log(data.type)
          console.log("返回结果"+data);
    },
    showPolygon(data){
      this.reset();
      const polygonCoordsfirst = this.allPoints[0].map((coord,index) => {
        var text = new AMap.Text({
            text:`拐角 ${this.base+index + 1}`,
            anchor:'center', // 设置文本标记锚点
            draggable:true,
            cursor:'pointer',
            angle:0,
            style:{
              'padding': '.5rem 1rem', // 调整内边距
                    'margin-bottom': '0.5rem', // 调整下边距
                    'border-radius': '.25rem',
                    'background-color': 'transparent',
                   'width': '3.5rem',// 减小宽度
                    'border-width': 0,
                    'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
                    'text-align': 'center',
                    'font-size': '16px', // 减小字体大小
                    'color': 'blue'
            },
            position: new AMap.LngLat(coord[1], coord[0])
        });
        text.setMap(this.map);
        this.textLabels.push(text); // 存储标签
        this.labeledPoints.add(`${coord[1]},${coord[0]}`); // 记录该点已经添加标签
        this.pointsReback.push({id:index+1,coord:[coord[1],coord[0]]})
        return new AMap.LngLat(coord[1], coord[0]); // 注意：AMap.Point 的参数是 (经度, 纬度)
      });
      this.base=this.allPoints[0].length;
      console.log("this.base"+this.base);
      const polygonfirst = new AMap.Polygon({
            path: polygonCoordsfirst, // 转换为 LngLat 对象
            strokeColor: "#06B6D4",   // 边框颜色
            fillColor: "#5EEAD4",      // 填充颜色
            fillOpacity: 0.5,       // 填充透明度
            strokeWeight: 2         // 边框宽度
        });
          //重新填充
          polygonfirst.setMap(this.map);
          this.overlays.push(polygonfirst);
          this.boundary.push(this.allPoints[0]);//给边界赋值
      let indexs=0;//后面做了一个判断，这个用来计数第几个
      let polygonCoords;
      if(data.type==="Polygon"){
        console.log("进入Polygon分支")
          polygonCoords = data.coordinates[0].map((coord,index) => {
          const coordKey = `${coord[1]},${coord[0]}`; // 创建唯一的坐标键
          this.polygonData.push([coord[0],coord[1]]);
          if (!this.labeledPoints.has(coordKey)) { //该点不存在标签才加入第几点
            indexs++;
            var text = new AMap.Text({
              text:`拐角 ${this.base+index+1}`,
              anchor:'center', // 设置文本标记锚点
              draggable:true,
              cursor:'pointer',
              angle:0,
              style:{
                'padding': '.5rem 1rem', // 调整内边距
                    'margin-bottom': '0.5rem', // 调整下边距
                    'border-radius': '.25rem',
                    'background-color': 'transparent',
                   'width': '3.5rem',// 减小宽度
                    'border-width': 0,
                    'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
                    'text-align': 'center',
                    'font-size': '16px', // 减小字体大小
                    'color': 'blue'
              },
              position: new AMap.LngLat(coord[1], coord[0])
          });
          text.setMap(this.map);
          this.textLabels.push(text); // 存储标签
          this.pointsReback.push({id:this.base+indexs,coord:[coord[1],coord[0]]})
          }  
          this.labeledPoints.add(`${coord[1]},${coord[0]}`); // 记录该点已经添加标签
          return new AMap.LngLat(coord[1], coord[0]); // 注意：AMap.Point 的参数是 (经度, 纬度)
        });
      }else if(data.type==="MultiPolygon"){
        console.log("进入MultiPolygon分支")
      data.coordinates.forEach((polygon, polygonIndex) => {
          let polygonCoords = []; // 初始化每个多边形的坐标数组
          polygon.forEach((coords, coordsIndex) => {
              coords.forEach((point) => {
                  const coordKey = `${point[1]},${point[0]}`; // 创建唯一的坐标键
                  this.polygonData.push([point[0], point[1]]);
                  console.log(point[0] + " sadffdas");
                  if (!this.labeledPoints.has(coordKey)) { // 检查该点是否已存在标签
                      indexs++;
                      var text = new AMap.Text({
                        text:`拐角 ${this.base+indexs+1}`,
                        anchor:'center', // 设置文本标记锚点
                        draggable:true,
                        cursor:'pointer',
                        angle:0,
                        style:{
                          'padding': '.5rem 1rem', // 调整内边距
                          'margin-bottom': '0.5rem', // 调整下边距
                          'border-radius': '.25rem',
                          'background-color': 'transparent',
                          'width': '3.5rem',// 减小宽度
                          'border-width': 0,
                          'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
                          'text-align': 'center',
                          'font-size': '16px', // 减小字体大小
                          'color': 'blue'
                        },
                        position: new AMap.LngLat(point[1], point[0])
                    });
                    text.setMap(this.map);
                    this.textLabels.push(text); // 存储标签
                      this.pointsReback.push({ id: this.base + indexs, coord: [point[1], point[0]] });
                      this.labeledPoints.add(coordKey); // 记录该点已经添加标签
                  }
                  // 将当前点加入 polygonCoords
                  polygonCoords.push(new AMap.LngLat(point[1], point[0])); // 注意：AMap.Point 参数为 (经度, 纬度)
              });
          }); 
          console.log(polygonCoords )
          console.log(" sadffadssafd"); // 输出 polygonCoords
          const polygonone = new AMap.Polygon({
            path: polygonCoords, // 转换为 LngLat 对象
            strokeColor: "#4F46E5",   // 边框颜色
            fillColor: "#A5B4FC",      // 填充颜色
            fillOpacity: 0.5,       // 填充透明度
            strokeWeight: 2         // 边框宽度
        });
          //重新填充
          polygonone.setMap(this.map);
          this.overlays.push(polygonone);
          console.log(polygonone)
          console.log("one");
          console.log(polygon); // 这里可能需要输出 polygonCoords 而不是 polygon
      });
      }else if(data.type==="FeatureCollection"){
        console.log("进入FeatureCollection分支");
        data.features.forEach(feature => {
          if (feature.geometry.type === "Polygon") {
              feature.geometry.coordinates.forEach(polygon => {
                  let polygonCoords = []; // 初始化每个多边形的坐标数组
                  polygon.forEach(point => {
                      const coordKey = `${point[1]},${point[0]}`; // 创建唯一的坐标键
                      this.polygonData.push([point[0], point[1]]);
                      console.log(point[0] + " sadffdas");
                      if (!this.labeledPoints.has(coordKey)) { // 检查该点是否已存在标签
                          indexs++;
                          var text = new AMap.Text({
                              text:`拐角 ${this.base+indexs+1}`,
                              anchor:'center', // 设置文本标记锚点
                              draggable:true,
                              cursor:'pointer',
                              angle:0,
                              style:{
                                'padding': '.5rem 1rem', // 调整内边距
                                'margin-bottom': '0.5rem', // 调整下边距
                                'border-radius': '.25rem',
                                'background-color': 'transparent',
                                'width': '3.5rem',// 减小宽度
                                'border-width': 0,
                                'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
                                'text-align': 'center',
                                'font-size': '16px', // 减小字体大小
                                'color': 'blue'
                              },
                              position:new AMap.LngLat(point[1], point[0])
                          });
                          text.setMap(this.map);
                          this.textLabels.push(text);
                          this.pointsReback.push({ id: this.base + indexs, coord: [point[1], point[0]] });
                          this.labeledPoints.add(coordKey); // 记录该点已经添加标签
                      }
                      // 将当前点加入 polygonCoords
                      polygonCoords.push(new AMap.LngLat(point[1], point[0])); // 注意：AMap.Point 参数为 (经度, 纬度)
                  });
                  console.log(polygonCoords);
                  console.log(" sadffadssafd"); // 输出 polygonCoords
                  const polygonOne = new AMap.Polygon({
                      path: polygonCoords, // 初始路径为空
                      strokeColor: "black",   // 边框颜色
                      fillColor: "blue",      // 填充颜色
                      fillOpacity: 0.5,       // 填充透明度
                      strokeWeight: 2         // 边框宽度
                  });
                  // 将多边形添加到地图
                  polygonOne.setMap(this.map);
                  this.overlays.push(polygonOne);
                  console.log("one");
                  console.log(polygon); // 这里可能需要输出 polygonCoords 而不是 polygon
              });
          }
      });
      }
      this.base+=indexs;
      console.log("2this.base"+this.base);
      this.drawingManager.polygon({
              fillColor:'blue',
              strokeColor:'#2'
              //同Polygon的Option设置
            });
      // 输出调试信息
      console.log(polygonCoords);
      console.log("one");
     // console.log(polygon);
      console.log(this.pointsReback);
      const polygon = new AMap.Polygon({
            path: polygonCoords, // 初始路径为空
            strokeColor: "black",   // 边框颜色
            fillColor: "blue",      // 填充颜色
            fillOpacity: 0.5,       // 填充透明度
            strokeWeight: 2         // 边框宽度
        });
        // 将多边形添加到地图
        polygon.setMap(this.map);
        this.overlays.push(polygon);
    },
    convertToGeoJSON() {
          // 将点对象转换为 GeoJSON 格式
          const coordinates = Object.values(this.points).map(point => [point.lng, point.lat]);
          return {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Polygon",
                  coordinates: [coordinates]  // GeoJSON 要求坐标数组是嵌套的
                },
                properties: {}
              }
            ]
          };
    },
    safeStringify(obj) {
      const seen = new WeakSet();
      return JSON.stringify(obj, (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            return; // 遇到循环引用，返回 undefined
          }
          seen.add(value);
        }
        return value;
      });
    },
    exportData(){
      // const geojson = this.convertToGeoJSON();
      // const blob = new Blob([JSON.stringify(geojson, null, 2)], {
      //   type: "application/json"
      // });
      // const url = URL.createObjectURL(blob);
      // const a = document.createElement("a");
      // a.href = url;
      // a.download = "data.geojson";  // 下载的文件名
      // a.click();
      // URL.revokeObjectURL(url);  // 释放 URL 对象
      console.log("输出待导出的数据");
  console.log(this.boundary);
  console.log(this.aisle);
  console.log(this.exit);
  console.log(this.subarea);

  const boundaryData = {
    type: "FeatureCollection",
    features: [{
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [this.boundary[0].map(coord => [coord[1], coord[0]])]
      },
      properties: {}
    }]
  };

  // 输出第一个文件数据
  console.log("Polygon Data:", this.safeStringify(boundaryData));

  const remainingPolygons = this.allPoints.slice(1);  // 获取其它多边形
  const swappedCoordinates = this.aisle.coordinates.map(polygon => 
    polygon.map(coord => [coord[1], coord[0]]) // 对调每个坐标点
);
  const hallAisleData = {
    type: "FeatureCollection",
    features: [{
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: swappedCoordinates // 修正为 this.aisle,经纬度进行对调
      },
      properties: {}
    }]
  };

  console.log("Subtracted Polygon Data (not fully implemented):", this.safeStringify(hallAisleData));


  const entranceExitData = {
        type: "FeatureCollection",
        features: this.exit.map(line => ({
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: line
          },
          properties: {
            description: "由两个点构成的线段"
          }
        }))
      };
  console.log("Selected Data:", this.safeStringify(entranceExitData));

  const subareaData = {
    type: "FeatureCollection",
    features: this.subarea.map(area => ({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [area.map(coord => [coord[1], coord[0]])] // 转换为 [经度, 纬度]
        },
        properties: {}
      }))
  };

  console.log("Overlays Data:", this.safeStringify(subareaData));

  const downloadFile = (data, filename) => {
    const blob = new Blob([this.safeStringify(data)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // 导出文件
  downloadFile(boundaryData, `${this.areaName}_hall_boundary.geojson`);
  downloadFile(hallAisleData, `${this.areaName}_hall_aisle.geojson`);
  downloadFile(entranceExitData, `${this.areaName}_hall_entrance_exit.geojson`);
  downloadFile(subareaData, `${this.areaName}_hall_subarea.geojson`);
    },
    reset() {
      this.map.remove(this.overlays)
      this.intersectingLine.forEach(line=>{
        line.setMap(null);
      })

      this.overlays.forEach(polygon=>{
          polygon.setMap(null);
      })
      this.textLabels.forEach(label => {
            label.setMap(null); // 移除标签
        });
      this.drawLine.forEach((line=>{
          line.setMap(null);
      }))
        this.textLabels = []; // 清空数组
       this.base=0;//重新开始记数
      this.labeledPoints.clear(); // 清空 labeledPoints    
       this.pointsReback=[];//清空用来选择出入口 的数据
       this.polygonData=[];
      },
    setPointNumber(){
      const path = e.obj.getPath();
      path.forEach((point, index) => {
        if (index < 4) { // 确保只更新前四个点
          this.points[`point${String.fromCharCode(65 + index)}`] = { // A, B, C, D
            lat: point.lat,
            lng: point.lng
          };
          this.polygonPoints.push(new AMap.Point(this.points[index].lat, this.points[index].lng)); 

        }
      });
    },
    getPoint() {
      document.getElementById("resultShape").innerHTML = '';
      for (let overlay of this.overlays) {
        const path = overlay.getPath();
        document.getElementById("resultShape").innerHTML += `${path.length}边形:<br/>`;
        path.forEach((point, index) => {
          document.getElementById("resultShape").innerHTML += `${index + 1}个点:(${point.lng},${point.lat});<br/>`;
        });
      }
    },
    editing(state) {
      for (let overlay of this.overlays) {
        state === 'enable' ? overlay.enableEditing() : overlay.disableEditing();
      }
    },
    showOverlayCount() {
      alert(this.overlays.length);
    },
    setAreaName(){
      this.$prompt('请输入区域名称', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: this.areaName,
          inputPattern: '',
          inputErrorMessage: '区域不正确'
        }).then(({ value }) => {
          this.$message({
            type: 'success',
            message: '区域是: ' + value
          });
          this.areaName=value;
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });       
        });   
    },
    setBoundary(){
        console.log("map",this.map);
        this.draw('polygon');
    },
    addSelect() {
      this.selectList.push({ selected: null }); // 添加新的选择器
    },
    handleCancel(){
      //取消分区
      this.subdivideRegion=false;

    },
    handleBlur(){
      console.log(this.row+"和"+this.column)
     if (this.row !== null && this.row !== '' && this.column !== null && this.column !== '') {
        // this.subdivideRegion=false;
        console.log('两个输入框都已填写，触发事件');
        // 这里可以添加其他逻辑
        this.drawline();
     }
    },
    handleSelectionChange(){
      console.log(this.selectedLocation+"dsadfasdffdsadfsafdsa")
    },
    drawline(){//子区域切分，画矩形
      this.row;
      this.column;
      console.log("画当前区域");
      console.log(this.selectedLocation);
      const num=this.selectedLocation.replace(/\D/g, '')-1;
      console.log(num);
      // const now=[this.ret.coordinates[num]];
      const now=[this.ret.features[num].geometry.coordinates];
      console.log("子区划分now，获取的多边形",now);
      this.child(now);
    },
    async child(polygons){
      //await new Promise(resolve => setTimeout(resolve, 5000));//解决函数异步问题
      const params=  {
        coordinates: polygons,
        horizontal_segments: this.row,
        vertical_segments: this.column
      };;
      console.log("激活python函数画子区域参数");
      console.log(params);
       //const response = await fetch('https://gdp-api.shunshun.guoch.xyz/api/children', {
        const response = await fetch('http://192.168.124.6:5050/api/children', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(params)
        });
        console.log(JSON.stringify(params))
        const data = await response.json();
        console.log("子区域划分结果"+data);
        console.log(data.rectangle)
        data.rectangles.forEach(polygon=>{
          this.subarea.push(polygon);//用来存储py返回的切割后的摊位多边形
          console.log(polygon);
        })
        //this.subare=data.rectangles;
        console.log("摊位",this.subarea);
                 // 绘制线段
        data.lines.forEach(line =>{
            var points = line.map(function(coord) {
              console.log(coord)
                return new AMap.LngLat(coord[1], coord[0]);
            });
            const polyline = new AMap.Polyline({
                path: points, // 设置线段的路径
                strokeColor: "black", // 线条颜色
                strokeWeight: 4,     // 线条宽度
                strokeOpacity: 1      // 线条透明度
            });
            // 将线段添加到地图
            polyline.setMap(this.map);
            this.intersectingLine.push(polyline);
        });
    },
    filteredPointsReback(index, isSecondSelect) {
      console.log("pointsReback",this.pointsReback);
      const selectedValues = this.selectList.flatMap(item => [item.selected, item.selected2]).filter(val => val !== null); // 过滤掉 null 值
      return this.pointsReback.filter(option => {
        const isSelected = selectedValues.includes(option.coord);
        // 过滤已选择的值
        return !isSelected || (isSecondSelect && option.coord === this.selectList[index].selected2);
      });
    },
    updateSelections() {
      console.log("当前选择:", this.selectList);
    },
    addSelect() {
      this.selectList.push({ selected: null, selected2: null });
    },
    resetSelectList() {
      this.selectList = [{ selected: null, selected2: null }];
    },
    submitSelections() {
      this.inandout = false;//关闭弹窗
      console.log("提交的选择:", this.selectList);
      this.selectList.forEach(line => {
        // 创建线段的坐标数组
        const lineSegment = [
          [line.selected[0], line.selected[1]], // [经度, 纬度]
          [line.selected2[0], line.selected2[1]]
        ];
        
        // 将线段推送到 exit 数组
        this.exit.push(lineSegment);
        });
      // 提交逻辑
    },
    handleSubdivide() {
      this.subdivideRegion = true; // 设置 subdivideRegion
        this.setArea(); // 调用其他函数
    },
    async setArea(){
      //await new Promise(resolve => setTimeout(resolve, 5000));//解决函数异步问题
      const params=this.allPoints;
      console.log(params);
      //const response = await fetch('https://gdp-api.shunshun.guoch.xyz/api/actionUnion', {
         const response = await fetch('http://192.168.124.6:5050/api/actionUnion', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(params)
        });
        console.log(JSON.stringify(params))
        const data = await response.json();
        this.ret=data;
        console.log(data);
        console.log(data.type);
        console.log("当前的data");
        this.reset();
        this.showPolygon(data);
        this.getAreaCentPoints(data);
    },
    showChildrenPolygon(data){
      this.reset();
      const polygonCoordsfirst = this.allPoints[0].map((coord,index) => {
        const label = new AMap.Text(`拐角 ${index + 1}`, {  position: new AMap.Point(coord[1], coord[0]) }); // 标注内容为 "点 1", "点 2", 等等
        label.setStyle({
          color: "#fff",
          backgroundColor: "blue",
          border: "none",
          padding: "5px",
          borderRadius: "5px",
          fontSize: "14px"
        });
        this.map.addOverlay(label); 
        this.labeledPoints.add(`${coord[1]},${coord[0]}`); // 记录该点已经添加标签
        this.pointsReback.push({id:index+1,coord:[coord[1],coord[0]]})
        return new AMap.Point(coord[1], coord[0]); // 注意：AMap.Point 的参数是 (经度, 纬度)
      });
      this.base=this.allPoints[0].length;
      console.log("this.base"+this.base);
      const polygonfirst = new AMap.Polygon(polygonCoordsfirst,{
        strokeColor: "red",
        fillColor: "red",
        strokeWeight: 3,
        strokeOpacity: 0.8,
        fillOpacity: 0.6,
        strokeStyle: 'solid'
      });
      let indexs=0;//后面做了一个判断，这个用来计数第几个
      const polygonCoords = data.coordinates[0].map((coord,index) => {
        const coordKey = `${coord[1]},${coord[0]}`; // 创建唯一的坐标键
        this.polygonData.push([coord[0],coord[1]]);
        if (!this.labeledPoints.has(coordKey)) { //该点不存在标签才加入第几点
          indexs++;
          const label = new AMap.Text(`拐角 ${this.base+indexs}`, {  position: new AMap.Point(coord[1], coord[0]) }); // 标注内容为 "点 1", "点 2", 等等
          label.setStyle({
          color: "#fff",
          backgroundColor: "blue",
          border: "none",
          padding: "5px",
          borderRadius: "5px",
          fontSize: "14px"
        });
        this.map.addOverlay(label); 
        this.pointsReback.push({id:this.base+indexs,coord:[coord[1],coord[0]]})
        }  
        this.labeledPoints.add(`${coord[1]},${coord[0]}`); // 记录该点已经添加标签
        return new AMap.Point(coord[1], coord[0]); // 注意：AMap.Point 的参数是 (经度, 纬度)
      });
      this.base+=indexs;
      console.log("2this.base"+this.base);
      const polygon = new AMap.Polygon(polygonCoords, {
        strokeColor: "black", // 边框颜色
        fillColor: "blue",  // 填充颜色
        fillOpacity: 0.5,   // 填充透明度
        strokeWeight: 2      // 边框宽度
      });
      console.log(polygonCoords);
      console.log("one");
      console.log(polygon);
      console.log(this.pointsReback);
    },
    getAreaCentPoints(data) {
        console.log(data.features);
        this.rectangles = data.features.map((feature, index) => {
            let sumLat = 0;
            let sumLng = 0;
            let pointCount = 0; // 记录有效点的数量
            // 获取多边形的坐标
            const polygons = feature.geometry.coordinates;
            polygons.forEach((polygon) => {
                console.log(polygon);
                polygon.forEach((point) => {
                    sumLat += point[0]; // 纬度
                    sumLng += point[1]; // 经度
                    pointCount++; // 有效点计数
                });
            });
            // 计算中心点
            let centerPoint = [sumLat / pointCount, sumLng / pointCount];
            console.log(centerPoint + "123456879");
            return {
                name: `子区域 ${index + 1}`,
                center: centerPoint,
                polygon: polygons
            };
        });
        console.log("所有的中心点");
        console.log(this.rectangles);
        // 显示中心点
        this.rectangles.forEach((rect, index) => {
            var text = new AMap.Text({
            text:`区域 ${index + 1}`,
            anchor:'center', // 设置文本标记锚点
            draggable:true,
            cursor:'pointer',
            angle:0,
            style:{
              'padding': '.5rem 1rem', // 调整内边距
              'margin-bottom': '0.5rem', // 调整下边距
              'border-radius': '.25rem',
              'background-color': 'transparent',
              'width': '3.5rem',// 减小宽度
              'border-width': 0,
              'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
              'text-align': 'center',
              'font-size': '16px', // 减小字体大小
              'color': 'blue'
            },
            position: new AMap.LngLat(rect.center[1], rect.center[0])
        });
        text.setMap(this.map);
        this.textLabels.push(text);
        });    
    },
    calculateAverageCoordinates(coords){
      let totalLat = 0;
      let totalLng = 0;

      coords.forEach(point => {
        totalLat += point[0]; // 累加纬度
        totalLng += point[1]; // 累加经度
      });

        // 计算平均值
      const averageLat = totalLat / coords.length;
      const averageLng = totalLng / coords.length;

      // 返回平均坐标数据
      return [averageLat,averageLng];
    },
    importData(){
      this.$message({
            type: 'info',
            message: '请导入.geojson后缀的文件'
          });    
    },
    hide(){
      //将标签临时隐藏
      //切换标签状态
      console.log("input")
      this.labelVisible = !this.labelVisible;    
      console.log("隐藏切换前")
      // this.textLabels.setMap(this.labelVisible ? this.map : null);
      this.textLabels.forEach(label => {
            label.setMap(this.labelVisible ? this.map : null); // 移除标签
        });
      console.log("隐藏切换后")
    },
    rollback(){
      //回退上一步画的多边形
      const lastPolygon=this.overlays.pop();
      lastPolygon.setMap(null);
      if (this.allPoints.length) {//这个数组用来存储给python传输的数据，也需要删除
        this.allPoints.pop();
      }
      for(let i=0;i<this.lastTextNumber;i++){
        this.textLabels.pop().setMap(null);
      }
      console.log("回退");
    },
    childset(){
      if(this.choicevalue===2){
        this.autioDraw();
      }else if(this.choicevalue===1){

      }

      this.circle();
      // this.showCascaderButton=false;
    },
    autioDraw(){

    },
    nextStep(){
      if(this.areaName===""&&this.active===1){
        this.setAreaName();
        return;
      }
      if(this.areaName===""&&this.active===1){
        this.setAreaName();
        return;
      }      
      this.active++;
      if(this.active===8){
        this.active=1;
      }
     // console.log("step",this.active);
      switch(this.active){
        case 1: this.setAreaName(); // 确保这是一个方法调用
          this.numstep=0;
          break;
        case 2:
        this.$message({
            type: 'info',
            message: '请在地图上画边界，双击左键结束画图'
          });   
        this.setBoundary(); // 确保这是一个方法调用
          break;
        case 3:this.showCascader = true ; // 确保这是一个方法调用
        this.$message({
            type: 'info',
            message: '请在地图上画对应子区域，双击左键结束画图'
          });   
          break;
        case 4:
                this.childset()//用来实现上一步圈选后的子区  
                this.inandout = true;
                this.showCascaderButton=false; // 确保这是一个方法调用
          break;
        case 5:
        this.handleSubdivide(); // 确保这是一个方法调用
                      this.showCascaderButton=false;
          break;
        case 6:this.exportData(); // 确保这是一个方法调用
                this.subdivideRegion=false;
          break;
        case 7:this.importData(); // 确保这是一个方法调用
          break;
        // case 8:this.exportData(); // 确保这是一个方法调用
        //   break;
        case 8:this.reset(); // 确保这是一个方法调用
                      this.showCascaderButton=false;
          break;
        case 9:this.hide();
          break;
        case 10:this.rollback();
          break;
        case 11:this.rollback();
          break;
       }   
    },
    previousStep(){

    },
    beforeUpload(file) {
      // 检查文件类型
      const isGeoJSON = file.type === 'application/json' || file.name.endsWith('.geojson');
      if (!isGeoJSON) {
        this.$message.error('上传文件只能是 GeoJSON 格式!');
      }
      return isGeoJSON;
    },
    handleFileChange(file, fileList) {
      // 更新文件列表
      this.fileList = fileList;

      const reader = new FileReader();
      reader.onload = (e) => {
        console.log("JSON.parse(e.target.result)",JSON.parse(e.target.result));
        // try {
          const geojson = JSON.parse(e.target.result);
          this.addGeoJSONToMap(geojson);
        // } catch (error) {
        //   this.$message.error('文件解析失败');
        // }
      };
      reader.readAsText(file.raw); // 读取文件内容
    },
    addGeoJSONToMap(geojson) {
      // 检查传入的 GeoJSON 数据是否有效
      if (!geojson || geojson.type !== "FeatureCollection") {
        console.error("Invalid GeoJSON data");
        return;
      }

      // 遍历每个 Feature
      geojson.features.forEach(feature => {
        if (feature.geometry.type === "Polygon") {
          const coordinates = feature.geometry.coordinates[0]; // 获取多边形坐标

          // 创建多边形对象
          const polygon = new AMap.Polygon({
            path: coordinates.map(coord => new AMap.LngLat(coord[0], coord[1])), // 转换为 AMap.LngLat 格式
            strokeColor: "#FF33FF", // 边界颜色
            fillColor: "#1791FC", // 填充颜色
            strokeWeight: 6, // 边界宽度
            fillOpacity: 0.5 // 填充透明度
          });

          // 将多边形添加到地图
          polygon.setMap(this.map);
        } else if (feature.geometry.type === "LineString") {
          const coordinates = feature.geometry.coordinates; // 获取线坐标

          // 创建折线对象
          const polyline = new AMap.Polyline({
            path: coordinates.map(coord => new AMap.LngLat(coord[0], coord[1])), // 转换为 AMap.LngLat 格式
            strokeColor: "#FF33FF", // 线条颜色
            strokeWeight: 4, // 线条宽度
            strokeOpacity: 1 // 线条透明度
          });

          // 将折线添加到地图
          polyline.setMap(this.map);
          this.drawLine.push(polyline);
        }
      });

      console.log("GeoJSON features added to the map");
    },
    resetData() {
      this.reset();
      this.showCascader = false;
      this.showCascaderButton = false;
      this.inandout = false;
      this.subdivideRegion = false;
      this.choicevalue = ""; // 0, 1, 2
      this.areaName = "";
      this.row = "";
      this.column = "";
      this.base = 0; // 记录点的基础的
      this.labeledPoints = new Set(); // 用于存储已添加标签的点
      this.areaNamBoundary = "";
      this.areaNameAisle = "";
      this.areaNameEntranceExit = "";
      this.areaNameSubarea = "";
      this.cascaderValue = "";
      this.areaCenterPoints = [];
      this.polygonPoints = [];
      this.rectangles = []; // 用于存储命名后的 area 的矩形数组，子区细分部分
      this.polygonData = [];
      this.map = null;
      this.overlays = [];
      this.drawingManager = null;
      this.styleOptions = {
        strokeColor: "red",
        fillColor: "red",
        strokeWeight: 3,
        strokeOpacity: 0.8,
        fillOpacity: 0.6,
        strokeStyle: 'solid'
      };
      this.ret = {};
      this.selectedLocation = [];
      this.locations = [
        { value: 'location1', label: '位置 1' },
        { value: 'location2', label: '位置 2' },
        { value: 'location3', label: '位置 3' },
      ];
      this.temporary = [];
      this.allPoints = []; // data 中定义 polygons 数组
      this.pointsReback = [];
      this.selectList = [{ selected: null }]; // 初始选择器
      this.childrenoptions = [ // 可选项
        { value: 'option1', label: '1' },
        { value: 'option2', label: '2' },
        { value: 'option3', label: '3' },
      ];
      this.options = [
        {
          value: 'A',
          label: '手动圈选',
          children: [
            { value: 'A1', label: '吸附边缘' },
            { value: 'A2', label: '不吸附' }
          ]
        },
        {
          value: 'B',
          label: '自动划分',
        }
      ];
      this.textLabels = [];
      this.activeName = ''; // 默认激活的标签
      this.labelVisible = true; // 用于地图标签初始化 显示
      this.lastTextNumber = 0; // 在回撤时，需要用来确定需要回撤标签的数量
      this.intersectingLine = [];
      this.active = 0;
      this.boundary = []; // 单独用来存储导出的数据。边界
      this.aisle = []; // 过道
      this.exit = []; // 出入口, 这个好弄 selectList
      this.subarea = []; // 细分区域.
      this.numstep = 0; // 用来区分进栈的步骤
      this.uploadUrl = ''; // 上传地址 (可以留空，因为我们不上传到服务器)
      this.fileList = []; // 存储选择的文件
    }
  }
};
</script>

<style scoped>
/* body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "微软雅黑";
} */
 .center-panel{
  position: absolute;  
  top: 10px; /* 距离顶部10px */
  left:40%;
  font-size: 26px;
  z-index:1000;
 }
.control-panel{
  position: absolute;
  top: 10px; /* 距离顶部10px */
  left: 10px; /* 距离左侧10px */
  z-index: 1000; /* 确保控件在最上层 */
}
.finishChild{
  position: absolute;
  bottom: 10px; /* 距离顶部10px */
  right: 10%; /* 距离左侧10px */
  z-index: 1000; /* 确保控件在最上层 */
}
.el-tabs__item {
    padding: 0 20px;
    height: 40px;
    box-sizing: border-box;
    line-height: 40px;
    display: inline-block;
    list-style: none;
    font-size: 24px;
    font-weight: 500;
    color: #303133;
    position: relative;
}
#app{
  display: flex; /* 使用 Flexbox 布局 */

}
#allmap {
  width: 108rem;
  height: 100vh;
  overflow: hidden;
}
#result {
  width: 100%;
  font-size: 12px;
}
dl, dt, dd, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
p {
  font-size: 12px;
}
dt {
  font-size: 14px;
  font-family: "微软雅黑";
  font-weight: bold;
  border-bottom: 1px dotted #000;
  padding: 5px 0 5px 5px;
  margin: 5px 0;
}
dd {
  padding: 5px 0 0 5px;
}
li {
  line-height: 28px;
}
.elbutton{
  width: 229px;
  height: 60px;
  font-size: 30px;
}
.select-box {
  position: absolute; /* 绝对定位选择框 */
  top: 20px; /* 根据需要调整位置 */
  left: 20px; /* 根据需要调整位置 */
  z-index: 1000; /* 确保选择框在地图之上 */
  background: white; /* 设置背景色 */
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
/* .input-row-column{
  width:40px;
} */
.input-boxx {
  position: absolute; /* 绝对定位选择框 */
  top: 20px; /* 根据需要调整位置 */
  right: 20%; /* 根据需要调整位置 */
  z-index: 1000; /* 确保选择框在地图之上 */
  background: white; /* 设置背景色 */
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  width: 150px; /* 设置宽度，根据需要调整 */
  /* 或使用 max-width: 200px; */
}
.input-box {
  border: 1px solid #dcdfe6; /* 边框颜色 */
  top: 20px; /* 根据需要调整位置 */
  right: 20%; /* 根据需要调整位置 */
  z-index: 1000; /* 确保选择框在地图之上 */
  border-radius: 4px; /* 圆角边框 */
  padding: 16px; /* 内边距 */
  margin: 16px 0; /* 外边距 */
  background-color: #f9f9f9; /* 背景颜色 */
  width: 100px; /* 设置宽度，根据需要调整 */
  /* 或使用 max-width: 200px; */
}
.input-row-column {
  margin-bottom: 12px; /* 控件之间的间距 */
}
.steps{
  position: absolute; /* 绝对定位选择框 */
  top: 20px; /* 根据需要调整位置 */
  right: 12rem; /* 根据需要调整位置 */
  z-index: 1000; /* 确保选择框在地图之上 */
  background: white; /* 设置背景色 */
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
#next-step{
  position: absolute; /* 绝对定位选择框 */
  top: 40%; /* 根据需要调整位置 */
  right: 12rem; /* 根据需要调整位置 */
  z-index: 1000; /* 确保选择框在地图之上 */
  background: white; /* 设置背景色 */
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
.large-font {
  font-size: 38px; /* 增加区域名称的字体大小 */
}
.el-select {
    display: inline-block;
    position: relative;
    margin: 5px;                /* 设置外边距 */
    padding: 8px 12px;          /* 设置内边距 */
    background-color: #fff;     /* 设置背景颜色 */
    border-radius: 4px;         /* 设置边框圆角 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
}
.el-dialog__body {
    padding: 10px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
}
.button-container {
  display: flex;            /* 使用 Flexbox 布局 */
  flex-direction: column;   /* 竖向排列 */
  align-items: flex-end;    /* 将按钮对齐到右侧 */
  position: absolute;        /* 绝对定位 */
  right: 20px;              /* 距离右侧的距离 */
  top: 60px;                /* 距离顶部的距离 */
  gap: 10px;                /* 按钮之间的间距 */
}
.custom-button {
  width: 8rem;          /* 设置按钮的固定宽度 */
  /* 或者使用百分比宽度，例如： */
  /* width: 100%; */
}
.upload-demo{
  top:10px;
  right: 20px; 
  height:20px;
}
.el-dialog {
    position: absolute; /* 改为绝对定位 */
    left: 0;           /* 设置左边距为 0 */
    top: 50%;          /* 垂直居中 */
    transform: translateY(-50%); /* 通过平移实现垂直居中 */
    margin: 0;        /* 移除默认的 margin */
    background: #FFF;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    box-sizing: border-box;
    width: 30%;
}
</style>