<template>
  <div id="container"></div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  name: "map-view",
  mounted() {
    this.initAMap();
  },
  unmounted() {
    this.map?.destroy();
  },
  methods: {
    initAMap() {
      window._AMapSecurityConfig = {
        securityJsCode: "a430f608bf068cee59ae33ac8268a329",
      };
      AMapLoader.load({
        key: "2781d6dd22a0848f2362c76d52c8f05f", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale","AMap.MouseTool"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            // 设置地图容器id
           // viewMode: "3D", // 是否为3D地图模式
            resizeEnable: true,
            expandZoomRange:true,
            zoom:19, //设置初始化级别
            zooms:[3,20], //设置缩放级别范围 3-20 级
            center: [116.281138,39.878109], // 初始化地图中心点位置
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
#container {
  width: 100%;
  height: 800px;
}
</style>
